/* eslint-disable no-useless-escape */
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import { createStandaloneToast } from "@chakra-ui/react";
import { toastDefaults } from "../util/constants";

const toast = createStandaloneToast();

export function formatNumber(x) {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return 0;
}

// Function to format monetary values with commas
export function formatMoney(x) {
  if (x) {
    return Number(x)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return 0;
}

export function formatMoneyToString(x) {
  let isNegative = false;
  let formattedNumber;
  if (x < 0) {
    isNegative = true;
  }
  x = renderNaira(Math.abs(x));
  if (x >= 1000000000) {
    formattedNumber = (x / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  } else if (x >= 1000000) {
    formattedNumber = (x / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  } else if (x >= 1000) {
    formattedNumber = (x / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  } else {
    formattedNumber = x;
  }
  if (isNegative) {
    formattedNumber = "-" + formattedNumber;
  }
  return formattedNumber;
}

export function renderNaira(x) {
  if (x) {
    return x / 100;
  }
  return 0;
}

export function convertDollarToNaira(x, exchangeRate) {
  if (x) {
    return Math.floor(x * (exchangeRate || 0));
  }
  return 0;
}
export function convertNairaToDollar(x, exchangeRate) {
  if (x && exchangeRate) {
    const y = x / exchangeRate;
    return y.toFixed(2);
  }
  return 0;
}

export function unFormatMoney(x) {
  if (x) {
    return x.replace(/\,/g, "");
  }
  return 0;
}

//  Returns days left for mandate to complete
export const renderMandateTimeLeft = (maturitydate) => {
  if (!maturitydate) return;
  const timeLeft = formatDistanceToNowStrict(new Date(maturitydate), {
    unit: "day",
  });

  if (timeLeft.split(" ").includes("0")) {
    return "Cycle complete";
  }
  return `${timeLeft} left`;
};

export const padWithdots = (str) => {
  if (!str) return "";
  return `**${str.slice(-4)}`;
};

export const getSavingsType = (type) => {
  switch (type) {
    case "says":
      return "Spend";
    case "saye":
      return "Earn";
    case "sayes":
      return "Earn & Spend";
    default:
      return " ";
  }
};

export const getUserSaveLimit = (userType) => {
  const userDescription =
    userType || JSON.parse(sessionStorage.getItem("userDescription"));
  if (userDescription.includes("business owner")) {
    return 60;
  } else if (userDescription.includes("working class")) {
    return 40;
  }
  return 20;
};

export const renderMandateHistoryDateTime = (dateTime) => {
  // 11:45 PM, 24-11-2019
  return `${new Date(dateTime).toLocaleDateString()} `;
};

export const renderStashHistoryDate = (dateTime) => {
  // 24-11-2019
  return `${new Date(dateTime).toLocaleDateString()} `;
};

/**
 * Function to search an array by a particular parameter
 * @param {array to search through} array
 * @param {key you want to search for} param
 * @param {value to compare with} value
 */
export const searchArrayByParam = (array, param, value) => {
  return (
    array &&
    array.length &&
    array.find((arrayItem) => arrayItem[param] === value)
  );
};

/**
 * Function to filter an array by a particular parameter
 * @param {array to search through} array
 * @param {key you want to search for} param
 * @param {value to compare with} value
 */
export const filterArrayByParam = (array, param, value) => {
  if (array.length === 0) {
    return [];
  }
  return (
    array &&
    array.length &&
    array.filter((arrayItem) => arrayItem[param] === value)
  );
};

export const checkUserVerified = (user) => {
  const { oldUser, emailVerified } = user || {};
  return (oldUser && !emailVerified) || emailVerified;
};

export const getAuth0ErrorMessage = (error) => {
  let message;
  if (error.code === "invalid_password") {
    message = `Your password must be: ${error.policy}`;
  } else if (typeof error.message === "string") {
    message = error.message;
  } else if (typeof error.description === "string") {
    message = error.description;
  } else if (typeof error.original === "string") {
    message = error.original;
  } else if (error.original && typeof error.original.message === "string") {
    message = error.original.message;
  } else {
    message = error.code; // Use error.code if no better option
  }

  return message;
};

export const toastSuccess = (message) => {
  toast({
    ...toastDefaults,
    title: "Success",
    description: message,
    status: "success",
  });
};

export const toastError = (message) => {
  toast({
    ...toastDefaults,
    status: "error",
    title: "Error",
    description: message,
  });
};
