import { apiRequest } from "../apiRequest";

export const fetchUserProfile = (uid) => {
  return apiRequest("/users/fetch/profile", "GET", null, { uid });
};

export const sendVerificationCode = (uid) => {
  return apiRequest(`/admin/users/send-verification/${uid}`, "POST", null);
};

export const suspendUser = (uid) => {
  return apiRequest(`/admin/users/suspend/${uid}`, "POST");
};

export const unSuspendUser = (uid) => {
  return apiRequest(`/admin/users/unsuspend/${uid}`, "POST");
};

export const deleteUser = (uid) => {
  return apiRequest(`/admin/users/delete/${uid}`, "POST", null);
};

export const restoreUser = (uid) => {
  return apiRequest(`/admin/users/restore/${uid}`, "POST", null);
};

// Fetch all users on platform
export const fetchAllUsers = () => {
  return apiRequest("/admin/users/fetch?limit=50", "GET", null);
};

export const fetchUser = ({uid}) => {
  return apiRequest(`/admin/users/fetch/${uid}`, "GET", null);
};

export const searchThroughUsers = (keyword) => {
  return apiRequest(`/admin/users/fetch?keyword=${keyword}`, "GET", null)
}

export const fetchMoreUsers = (lastFetchedId) => {
  return apiRequest(`/users/admin/fetch/more/${lastFetchedId}`, "GET", null);
};

export const fetchUserByEmail = (email) => {
  return apiRequest(`/users/admin/fetch/email/${email}`, "GET", null);
};

export const payCustomer = (email, amount) => {
  return apiRequest("/admin/accounting/pay-user", "POST", {email, amount});
}
