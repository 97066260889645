import createDataContext from "./createContext";
import {
  fetchAmbassadors,
  fetchMoreAmbassador,
  fetchAmbassadorById,
} from "../util/operations/ambassadors";
import { toastDefaults } from "../util/constants";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCHING_AMBASSADORS_DATA":    
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_AMBASSADORS_SUCCESS":
      return {
        ...state,
        ambassadors: action.payload,
        isLoading: false,
        lastFetchedId: action.payload[action.payload.length - 1]?.uid,
      };
    case "FETCH_AMBASSADOR_STATISTICS_SUCCESS":
      return {
        ...state,
        statistics: action.payload,
        isLoading: false,
      };
    case "FETCH_MORE_AMBASSADORS_SUCCESS":
      return {
        ...state,        
        users: [...state.users, ...action.payload],
        lastFetchedId: action.payload[action.payload.length - 1].uid,
      };
      case "FETCHING_AMBASSADOR_DATA":
        return {
          ...state,
          ambassadorInfo: {},
          usersDataIsLoading: true,
        }
    case "FETCH_SINGLE_USER_SUCCESS":
      return {
        ...state,
        usersDataIsLoading: false,
        ambassadorInfo: action.payload
      };
    case "ERROR_FETCHING_AMBASSADORS_DATA":
      return {
        ...state,
        usersDataIsLoading: false,
      };
    default:
      return state;
  }
};

const getAllAmbassadors = (dispatch) => async (toast) => {
  dispatch({
    type: "FETCHING_AMBASSADORS_DATA",
  });
  const { status, data, message } = await fetchAmbassadors();  

  if (!status) {
    await dispatch({
      type: "ERROR_FETCHING_AMBASSADORS_DATA",
    });
    await toast({
      ...toastDefaults,
      status: "error",
      title: "Error",
      description: message,
    });
  }

  if (data) {
    await dispatch({ type: "FETCH_AMBASSADORS_SUCCESS", payload: data.ambassadors });
    await dispatch({ type: "FETCH_AMBASSADOR_STATISTICS_SUCCESS", payload: data.statistics });
  }
};

const getMoreAmbassadors = (dispatch) => async (lastFetchedId, toast) => {
  const { status, data, message } = await fetchMoreAmbassador(lastFetchedId);

  await dispatch({
    type: "FETCHING_USERS_DATA",
  });

  if (!status) {
    await toast({
      ...toastDefaults,
      status: "error",
      title: "Error",
      description: message,
    });

    await dispatch({
      type: "ERROR_FETCHING_AMBASSADORS_DATA",
    });
  }

  data &&
    (await dispatch({ type: "FETCH_MORE_USERS_SUCCESS", payload: data }));
};

const getAmbassadorByID = (dispatch) => async (id, toast) => {
  dispatch({
    type: "FETCHING_AMBASSADOR_DATA",
  });
  const { status, data, message } = await fetchAmbassadorById(id);

  if(!status) {
    await dispatch({
      type: "ERROR_FETCHING_AMBASSADORS_DATA",
    });
    (await toast({
      ...toastDefaults,
      status: "error",
      title: "Error",
      description: message,
    }));
  }
    

  data &&
    (await dispatch({ type: "FETCH_SINGLE_USER_SUCCESS", payload: data }));
};


export const { Context, Provider } = createDataContext(
  reducer,
  {
    getAllAmbassadors,
    getMoreAmbassadors,
    getAmbassadorByID,
  },
  {
    amabassadors: [],
    statistics: [],
    ambassadorInfo: {},
    filter: "",
    sort: ''
  }
);
