import { toastDefaults } from "../../util/constants";

export const handleUpdate = async (
  formData,
  setIsLoading,
  toast,
  setModal,
  data
) => {
  setIsLoading(true);
  const response = await data.trigger(formData.conversionRate);
  await data.fetch();

  if (response.status) {
    toast({
      ...toastDefaults,
      title: "Success",
      description: response.message,
      status: "success",
    });
    setModal({
      isOpen: false,
    });
  } else {
    toast({
      ...toastDefaults,
      title: "Error",
      description: response.message,
      status: "error",
    });
  }
  return setIsLoading(false);
};
