import createDataContext from "./createContext";
import {
  fetchUser,
  fetchAllUsers,
  fetchMoreUsers,
} from "../util/operations/users";
import { fetchCashReserve } from "../util/operations/cash-reserve";
import { fetchUsersMandates } from "../util/operations/mandates";
import { fetchUserAccounts } from "../util/operations/accounts";
import { fetchUserTransactions } from "../util/operations/transactions";
import { fetchUserWithdrawals } from "../util/operations/withdrawals";
import { fetchUserLogs } from "../util/operations/logs";
import { fetchUserDebits } from "../util/operations/debits";
import { toastDefaults } from "../util/constants";
import { toastSuccess, toastError } from "../util/helper-functions";

const userProperties = {
  mandates: (userID) => fetchUsersMandates(userID),
  linkedAccounts: (userID) => fetchUserAccounts(userID),
  transactions: (userID) => fetchUserTransactions(userID),
  withdrawals: (userID) => fetchUserWithdrawals(userID),
  logs: (userID) => fetchUserLogs(userID),
  debits: (userID) => fetchUserDebits(userID),
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCHING_USERS_DATA":
      return {
        ...state,
        usersDataIsLoading: true,
      };
    case "FETCHING_USER_DATA":
      return {
        ...state,
        userDataIsLoading: true,
      };
    case "FETCH_USERS_SUCCESS":
      return {
        ...state,
        users: action.payload,
        usersDataIsLoading: false,
        lastFetchedId: action.payload[action.payload.length - 1]?.uid,
      };
    case "FETCH_MORE_USERS_SUCCESS":
      return {
        ...state,
        usersDataIsLoading: false,
        users: [...state.users, ...action.payload],
        lastFetchedId: action.payload[action.payload.length - 1]?.uid,
      };
    case "EDIT_USER_STATUS_SUCCESS":
      return {
        ...state,
        users: action.payload,
      };
    case "FETCH_SINGLE_USER_SUCCESS":
      return {
        ...state,
        usersDataIsLoading: false,
        users: [...state.users, action.payload],
      };
    case "FETCH_USER_SUCCESS":
      return {
        ...state,
        userDataIsLoading: false,
        user: action.payload,
      };
    case "EDIT_USER_SUCCESS":
      const edit = {
        ...state,
        userDataIsLoading: false,
        user: { user: { ...state.user.user, [action.payload.key]: action.payload.value}},
      };
      console.log(edit);
      return edit;
    case "FETCH_USER_CASH_RESERVE_SUCCESS":
      return {
        ...state,
        userDataIsLoading: false,
        cashReserve: action.payload,
      };
    case "APPEND_USER_DATAPOINT":
      return {
        ...state,
        users: action.payload,
      };
    case "ERROR_FETCHING_USERS_DATA":
      return {
        ...state,
        usersDataIsLoading: false,
      };
    default:
      return state;
  }
};

const dispatchEditUserStatus =
  (dispatch) => (uid, users, dataPoint, newStatus) => {
    const userIndex = users.findIndex((u) => u.uid === uid);

    console.log({userIndex});
    const updatedUser = {
      ...users[userIndex],
      [dataPoint]: newStatus,
    };

    console.log({updatedUser});

    users[userIndex] = updatedUser;

    dispatch({ type: "EDIT_USER_STATUS_SUCCESS", payload: users });
  };

const dispatchEditUser =
  (dispatch) => ({key, value}) => {
    dispatch({ type: "EDIT_USER_SUCCESS", payload: {key, value} });
  };



const getAllUsers = (dispatch) => async (toast) => {

  dispatch({
    type: "FETCHING_USERS_DATA",
  });

  const { status, data, message } = await fetchAllUsers();

  if (!status) {
    await dispatch({
      type: "ERROR_FETCHING_USERS_DATA",
    });
    toast({
      ...toastDefaults,
      status: "error",
      title: "Error",
      description: message,
    });
  }

  data && (await dispatch({ type: "FETCH_USERS_SUCCESS", payload: data }));
};

const getUser = (dispatch) => async (uid) => {

  await dispatch({
    type: "FETCHING_USER_DATA",
  });

  const { status, data, message } = await fetchUser({uid});

  if (!status) {
   await dispatch({
      type: "ERROR_FETCHING_USERS_DATA",
    });
    return toastError(message);
  }
  
  data && (await dispatch({ type: "FETCH_USER_SUCCESS", payload: data }));
  toastSuccess(message);
};
const getUserCashReserve = (dispatch) => async (uid) => {

  await dispatch({
    type: "FETCHING_USER_DATA",
  });

  const { status, data, message } = await fetchCashReserve(uid);

  if (!status) {
   await dispatch({
      type: "ERROR_FETCHING_USERS_DATA",
    });
    return toastError(message);
  }
  
  data && (await dispatch({ type: "FETCH_USER_CASH_RESERVE_SUCCESS", payload: data }));
  toastSuccess(message);
};

const getMoreUsers = (dispatch) => async (lastFetchedId, toast, users) => {
  const { status, data, message } = await fetchMoreUsers(lastFetchedId);

  await dispatch({
    type: "FETCHING_USERS_DATA",
  });

  if (!status) {
    await toast({
      ...toastDefaults,
      status: "error",
      title: "Error",
      description: message,
    });

    await dispatch({
      type: "ERROR_FETCHING_USERS_DATA",
    });
  }

  data &&
    (await dispatch({ type: "FETCH_MORE_USERS_SUCCESS", payload: data }));
};

const getUserProperty =
  (dispatch) => async (userID, allUsersData, propertyKey, toast) => {
    const { status, data, message } = await userProperties[propertyKey](userID);

    !status &&
      (await toast({
        ...toastDefaults,
        status: "error",
        title: "Error",
        description: message,
      }));

    if (data) {
      const userIndex = allUsersData.findIndex((u) => u.uid === userID);

      const updatedUsersData = {
        ...allUsersData[userIndex],
        [propertyKey]: data.data,
      };

      allUsersData[userIndex] = updatedUsersData;

      dispatch({ type: "APPEND_USER_DATAPOINT", payload: allUsersData });
    }
  };

export const { Context, Provider } = createDataContext(
  reducer,
  {
    getAllUsers,
    getMoreUsers,
    getUserCashReserve,
    getUserProperty,
    getUser,
    dispatchEditUserStatus,
    dispatchEditUser
  },
  {
    users: [],
    user: {},
    cashReserve: {},
    usersDataIsLoading: false,
    userDataIsLoading: false,
  }
);
