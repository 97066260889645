import createDataContext from "./createContext";
import { fetchAllLogs, fetchMoreLogs } from "../util/operations/logs";
import { toastDefaults } from "../util/constants";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_LOGS_SUCCESS":
      return {
        ...state,
        isLoading: false,
        app_logs: action.payload,
        lastFetchedId: action.payload[action.payload.length - 1]._id,
      };
    case "FETCH_MORE_LOGS_SUCCESS":
      return {
        ...state,
        isLoading: false,
        app_logs: [...state.app_logs, ...action.payload],
        lastFetchedId: action.payload[action.payload.length - 1]._id,
      };
    case "FETCHING": {
      return {
        ...state,
        isLoading: true,
      };
    }

    default:
      return state;
  }
};

const getAllLogs = (dispatch) => async (app_logs, toast) => {
  if (app_logs && app_logs.length > 0) {
    return;
  }
  dispatch({
    type: "FETCHING",
  });
  const { status, data, message } = await fetchAllLogs();
  !status &&
    (await toast({
      ...toastDefaults,
      status: "error",
      title: "Error",
      description: message,
    }));
  data && (await dispatch({ type: "FETCH_LOGS_SUCCESS", payload: data }));
};

const getMoreLogs = (dispatch) => async (lastFetchedId, toast) => {
  dispatch({
    type: "FETCHING",
  });

  const { status, data, message } = await fetchMoreLogs(lastFetchedId);

  !status &&
    (await toast({
      ...toastDefaults,
      status: "error",
      title: "Error",
      description: message,
    }));
  data &&
    (await dispatch({ type: "FETCH_MORE_LOGS_SUCCESS", payload: data }));
};

// For single log data =>  check app_logs.. if it is there, return it... else fetch it and add it there
export const { Context, Provider } = createDataContext(
  reducer,
  {
    getAllLogs,
    getMoreLogs,
  },
  {
    app_logs: [],
    user_logs: {
      user: {
        sample_uid: [],
      },
    },
  }
);
