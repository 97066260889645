import React from "react";
import AuthForm from "../../components/AuthForm";
import useValidateInvite from "../../util/hooks/useValidateInvite";
import { useRouter } from "../../util/router";


const AuthPage = () => {
  const router = useRouter();
  const validatedState = useValidateInvite(router.location);

  return (
    <>
      <AuthForm validatedState={validatedState}/>
    </>
  );
};

export default AuthPage;
