import {useEffect, useReducer} from "react"
import { verifyAdmin } from "../operations/auth";
import { useRouter } from "../router";

const initialState = { status: 'idle', message: ''};
function reducer(state, action) {
    switch (action.type) {
      case 'idle':
        return { status: 'idle', message: ''};
      case 'loading':
        return {status: 'loading', message: 'loading...'};
      case 'success':
        return {status: 'success', message: action.payload};
      case 'failed':
        return {status: 'failed', message: action.payload};
      default:
        throw new Error();
    }
  }

function useValidateInvite(location) {
    // State and setters for debounced value
    const [state, dispatch] = useReducer(reducer, initialState);
    const router = useRouter();
    useEffect(
      () => {
        // eslint-disable-next-line no-unused-vars
        let start = true;
        if (location.pathname === "/invite") {
            const {code, email } = router.query;
            dispatch({ type: 'loading'});
            verifyAdmin({code, email}).then((res) => {
                if (res.status) {
                    return dispatch({ type: 'success', payload: `${res.message}. You can sign in now`});
                }
                return dispatch({ type: 'failed', payload: res.message});
            }).catch(() => {
            });
          }

        return () => {
          start = false;
          dispatch({ type: 'idle'})
         };
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [] 
    );
    return state;
  }

  export default useValidateInvite;