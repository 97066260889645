import { Flex, Icon } from "@chakra-ui/react";
import { useRouter } from "../../../util/router";

const NavItem = (props) => {
  const { icon, children, active, path, ...rest } = props;
  const router = useRouter();
  return (
    <Flex
      onClick={() => router.push(`/dashboard${path}`)}
      align="center"
      px="4"
      pl="10"
      py="5"
      cursor="pointer"
      color={active ? "brand.primary" : "brand.white"}
      bg={active ? "brand.white" : ""}
      _hover={{
        color: "brand.grey",
      }}
      role="group"
      fontWeight="semibold"
      transition=".15s ease"
      {...rest}
    >
      {icon && <Icon mr="2" boxSize="8" as={icon} />}
      {children}
    </Flex>
  );
};

export default NavItem;
