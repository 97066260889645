import React, { useState } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Alert,
  AlertIcon,
  AlertDescription,
  Text,
} from "@chakra-ui/react";

import { useForm } from "react-hook-form";
import { useAuth } from "../../util/auth";
import { useRouter } from "../../util/router";

export default function SplitScreen({ validatedState }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const auth = useAuth();

  const submit = async (formData) => {
    setIsLoading(true);
    const { message, status } = await auth.login(formData);

    if (status) {
      console.log("WHAT IS THIS STATUS", status);

      return router.push("/dashboard");
    }

    setLoginError(message);
    setIsLoading(false);

    return setTimeout(function () {
      return setLoginError(false);
    }, 3000);
  };

  return (
    <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack spacing={4} w={"full"} maxW={"md"}>
          <Heading fontSize={"2xl"}>Sign in to your account</Heading>
          {(loginError || validatedState?.status === "failed") && (
            <Alert status="error">
              <AlertIcon />
              <AlertDescription>
                {loginError || validatedState?.message}
              </AlertDescription>
            </Alert>
          )}
          {validatedState?.status === "success" && (
            <Alert status="success">
              <AlertIcon />
              <AlertDescription>{validatedState?.message}</AlertDescription>
            </Alert>
          )}
          <form onSubmit={handleSubmit(submit)}>
            <FormControl marginBottom="4" id="email">
              <FormLabel color={errors?.email ? "brand.error" : "inherit"}>
                {errors?.email ? errors.email.message : " Email address"}
              </FormLabel>
              <Input
                {...register("email", {
                  required: "This is required",
                })}
                name="email"
                type="email"
              />
            </FormControl>
            <FormControl marginBottom="4" id="password">
              <FormLabel color={errors?.password ? "brand.error" : "inherit"}>
                {errors?.password ? errors.password.message : " Password "}
              </FormLabel>
              <Input
                {...register("password", {
                  required: "This is required",
                })}
                type="password"
              />
            </FormControl>
            <Stack spacing={6}>
              {/* <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Checkbox>Remember me</Checkbox>
                <Link color={"blue.500"}>Forgot password?</Link>
              </Stack> */}
              <Button
                isLoading={isLoading}
                loadingText="Hang on.."
                type="submit"
                bg="brand.primary"
                variant={"solid"}
                _hover={{
                  background: "brand.primary",
                  shadow: "md",
                }}
                shadow="sm"
                _active={{
                  shadow: "md",
                  transform: "translateY(.3rem)",
                }}
              >
                Get in
              </Button>
            </Stack>
          </form>
        </Stack>
      </Flex>
      <Flex bg="brand.lightYellow" flex={1}>
        <Stack py="30%">
          <Text fontSize="350%" color="white" textAlign="center">
            {" "}
            👀{" "}
          </Text>
          <Text
            color="brand.primary"
            textAlign="center"
            w="70"
            m="auto"
            fontSize="150%"
            fontWeight="900"
            p="5"
          >
            If you are not a myStash admin and you are here, Why are you here ?
            What are you looking for ? <br />
          </Text>
        </Stack>
      </Flex>
    </Stack>
  );
}
