import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { handleUpdate } from "./helper";

const TriggerWithRate = ({ setModal, data }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const submit = async (formData) => {
    setIsLoading(true);
    formData.conversionRate = parseInt(formData.conversionRate);
    return handleUpdate(formData, setIsLoading, toast, setModal, data);
  };

  return (
    <>
      <Stack spacing={4} w={"full"} maxW={"md"}>
        <form onSubmit={handleSubmit(submit)}>
          <FormControl marginBottom="4" id="email">
            <FormLabel color={errors?.email ? "red" : "inherit"}>
              {errors?.email ? errors.email.message : " Savings Rate"}
            </FormLabel>
            <Input
              {...register("conversionRate")}
              name="conversionRate"
              type="number"
              placeholder="Amount in naira"
            />
          </FormControl>
          <Stack marginTop="10" spacing={6}>
            <Button
              isLoading={isLoading}
              loadingText="Hang on.."
              type="submit"
              colorScheme={"blue"}
              variant={"solid"}
            >
              Trigger Savings
            </Button>
          </Stack>
        </form>
      </Stack>
    </>
  );
};

export default TriggerWithRate;
