import React from "react";
import { Switch, Route, Router } from "./../../util/router";
import AuthPage from "../auth";
import NotFoundPage from "../not-found";
import Dashboard from "../dash";
import ContextProvider from "../../contexts/ContextProvider";

import { theme } from "../../styles/theme";

import { ChakraProvider } from "@chakra-ui/react";
import { ProvideAuth } from "../../util/auth";

import "@fontsource/montserrat";

const App = () => {
  return (
    <>
      <ChakraProvider theme={theme}>
        <ContextProvider>
          <Router>
            <ProvideAuth>
              <Switch>
                <Route path="/dashboard" component={Dashboard} />
                <Route path={["/", "/invite"]} exact component={AuthPage} />
                <Route path="*" component={NotFoundPage} />
              </Switch>
            </ProvideAuth>
          </Router>
        </ContextProvider>
      </ChakraProvider>
    </>
  );
};

export default App;
