import { apiRequest } from "../apiRequest";

export const createCompany = (data) => {
  return apiRequest(`/admin/client-company/create`, "POST", data, null
  );
};

export const fetchCompanies = (data) => {
  return apiRequest(
    `/admin/client-company/fetch`, "GET", null, null
  );
};

export const createEmployee = (data) => {
  return apiRequest(
    `/admin/client-company/create/employee`, "POST", data, null
  );
};

export const deleteEmployee = (data) => {
  return apiRequest(
    `/admin/client-company/delete/${data.companyId}/${data.mandateId}/employee/${data.uid}`, "DELETE", null, null
  );
};

export const editEmployee = ({companyId, mandateId, uid, ...data}) => {
  return apiRequest(
    `/admin/client-company/edit/${companyId}/${mandateId}/employee/${uid}`, "PATCH", data, null
  );
};

export const fetchEmployees = (data) => {
  return apiRequest(
    `/admin/client-company/fetch/${data.companyId}/employees`, "GET", null, null
  );
};

export const triggerSavingsForEmployee = (data) => {
  return apiRequest(`/admin/client-company/perk/trigger`, "POST", data, null);
};

export const triggerSavingsForAllEmployees = (data) => {
  return apiRequest(
    `/admin/client-company/perk/trigger/all`, "POST", data, null
  );
};
