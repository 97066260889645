import { apiRequest } from "../apiRequest";

export const fetchUsersMandates = (uid) => {
  return apiRequest(`/admin/mandate/fetch-user/${uid}`, "GET", null);
};

// Fetch Mandates accross app
export const fetchAllMandates = () => {
  return apiRequest(`/admin/mandate/fetch`, "GET", null);
};

export const fetchMoreMandates = (lastFetchedId) => {
  return apiRequest(`/mandate/admin/fetch/more/${lastFetchedId}`, "GET", null);
};

export const fetchMandateData = (mandateId) => {
  return apiRequest(`/admin/mandate/fetch/${mandateId}`, "GET", null);
};

export const fetchMandateHistory = (mandateId) => {
  return apiRequest(`/stash-history/admin/fetch/${mandateId}`, "GET");
};

export const deleteMandate = (uid, mandateId) => {
  return apiRequest(`/admin/mandate/delete/${uid}/${mandateId}`, "DELETE");
};

export const toggleActivateMandate = (mandateId) => {
  return apiRequest(`/admin/mandate/toggle/active/${mandateId}`, "PATCH");
};

export const fundMandate = (data) => {
  return apiRequest(`/admin/accounting/fund/mandate`, "POST", data);
};

export const chargeMandate = (data) => {
  return apiRequest(`/admin/accounting/charge/mandate`, "POST", data);
};

export const editMandate = (data, userId, mandateId) => {
  return apiRequest(
    `/admin/mandate/edit/${userId}/${mandateId}`,
    "PATCH",
    data
  );
};
