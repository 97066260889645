import { apiRequest } from "../apiRequest";

export const fetchCashReserve = (uid) => {
  return apiRequest(`/admin/cash-reserve/fetch/${uid}`, "GET", null);
};

export const fundCashreserve = (data) => {
  return apiRequest(`/admin/accounting/fund/cash-reserve`, "POST", data);
};

export const chargeCashReserve = (data) => {
  return apiRequest(`/admin/accounting/charge/cash-reserve`, "POST", data);
};
