import { apiRequest } from "../apiRequest";

export const fetchAmbassadors = () => {
  return apiRequest("/admin/ambassadors/fetch", "GET");
};

export const fetchMoreAmbassador = (lastFetchedId) => {
  return apiRequest(`/users/admin/fetch/more/${lastFetchedId}`, "GET", null);
};

export const fetchAmbassadorById = (id) => {
  return apiRequest(`/admin/ambassadors/fetch/${id}`, "GET", null);
};

export const fetchAmbassadorByCode = (code) => {
  return apiRequest(`/admin/ambassadors/referrals/${code}`, "GET", null)
}

export const searchThroughAmbassadors = (keyword) => {
  return apiRequest(`/ambassadors?keyword=${keyword}`, "GET", null)
}

export const deactivateAmbassador = (id) => {
  return apiRequest(`/ambassadors/deactivate/${id}`, "PATCH")
}

export const activateAmbassador = (id) => {
  return apiRequest(`/ambassadors/deactivate/${id}`, "PATCH")
}
