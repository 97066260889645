import { apiRequest } from "../apiRequest";

export const fetchUserAccounts = (uid) => {
  return apiRequest(`/admin/bank-account/fetch-user/${uid}`, "GET", null);
};

export const fetchAccountDetails = (accountId) => {
  return apiRequest(`/account/fetch/details/${accountId}`, "GET", null);
};

export const fetchAllAccounts = () => {
  return apiRequest(`/account/admin/fetch`, "GET", null);
};

export const fetchMoreAccounts = (lastFetchedId) => {
  return apiRequest(`/account/admin/fetch/${lastFetchedId}`, "GET", null);
};

export const deleteCardAuth = (accountId) => {
  return apiRequest(`/admin/bank-account/remove-card/${accountId}`, "DELETE", null);
};

export const deleteAccount = (accountId) => {
  return apiRequest(`/admin/bank-account/delete/${accountId}`, "DELETE", null);
};
