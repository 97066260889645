import React, { useState, useEffect, useContext, createContext } from "react";
import { apiRequest } from "./apiRequest";
import { useHistory } from "./router";
import { Spinner, Center } from "@chakra-ui/react";
import { H } from "highlight.run";
import { getAuth0ErrorMessage } from "./helper-functions";

const authContext = createContext();

// Context Provider component that wraps your app and makes auth object
// available to any child component that calls the useAuth() hook.
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook that enables any component to subscribe to auth state
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);

  const handleAuthStateChange = async () => {
    const response = await apiRequest("/auth/profile/fetch", "GET", null, null);

    if (response && response.status) {
      return setUser(response.data);
    }

    return setUser(false);
  };

  const login = async (loginData) => {
    try {
      const response = await apiRequest(
        "/auth/login",
        "POST",
        {
          email: loginData.email,
          password: loginData.password,
        },
        null
      );

      H.identify(loginData.email, {
        id: response.data?.user.uid,
      });

      if (!response.data) {
        return {
          status: false,
          message: response.message,
        };
      }

      const user = response.data?.user;

      console.log("THIS USER", user);

      if (user?.role !== "admin" && user?.role !== "super") {
        return {
          status: false,
          message: "You do not have admin priviledges",
        };
      }

      const { token } = response.data;
      sessionStorage.setItem("token", token);
      await handleAuthStateChange();

      return {
        status: true,
        message: "Welcome back",
      };
    } catch (error) {
      const message = getAuth0ErrorMessage(error);
      return {
        status: false,
        message,
      };
    }
  };

  const logout = async () => {
    sessionStorage.removeItem("token");

    return window.location.reload();
  };

  useEffect(() => {
    handleAuthStateChange();
  }, []);

  return {
    user,
    login,
    logout,
    handleAuthStateChange,
  };
}

// A Higher Order Component for requiring authentication
export const requireAuth = (Component) => {
  return (props) => {
    // Get authenticated user
    const auth = useAuth();
    const history = useHistory();

    useEffect(() => {
      // Redirect if not signed in
      if (auth.user === false) {
        history.replace("/");
      }

      // if (auth?.user?.role !== "admin" || auth?.user?.role !== "super") {
      //   history.replace("/");

      // }

      // eslint-disable-next-line
    }, [auth]);

    // Show loading indicator
    // We're either loading (user is null) or we're about to redirect (user is false)
    if (!auth.user) {
      return (
        <Center h="100vh">
          {" "}
          <Spinner size="lg" color="brand.primary" />{" "}
        </Center>
      );
    }

    // Render component now that we have user
    return <Component {...props} />;
  };
};
