import { apiRequest } from "../apiRequest";

export const fetchUserDebits = (uid) => {
  return apiRequest(`/debit/fetch/${uid}`, "GET", null);
};

export const fetchMoreUserDebits = (uid, lastFetchedId) => {
  return apiRequest(`/debit/fetch/${uid}/more/${lastFetchedId}`, "GET", null);
};

// Fetch Debits accross app
export const fetchAllDebits = () => {
  return apiRequest(`/debit/admin/fetch`, "GET", null);
};

export const fetchMoreDebits = (lastFetchedId) => {
  return apiRequest(`/debit/admin/fetch/more/${lastFetchedId}`, "GET", null);
};

export const fetchDebitDetails = (debitReference) => {
  return apiRequest(
    `/debit/admin/fetch/details/${debitReference}`,
    "GET",
    null
  );
};
