import { extendTheme } from "@chakra-ui/react";

const Button = {
  // The styles all button have in common
  baseStyle: {
    fontWeight: "bold",
    borderRadius: "12px",
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      fontSize: "sm",
      px: 4,
      py: 3,
    },
    md: {
      fontSize: "md",
      px: 6,
      py: 4,
    },
    lg: {
      fontSize: "lg",
      px: 16,
      py: 6,
    },
  },
  // Two variants: outline and solid
  variants: {
    outline: {
      border: "2px solid",
      borderColor: "#961BA9",
      color: "#961BA9",
    },
    light: {
      bg: "#FFF6FF",
      fontWeight: "normal",
      borderRadius: "8px",
      color: "#961BA9",
      boxShadow: "none",
    },
    solid: {
      bg: "#961BA9",
      color: "white",
      boxShadow: "0px 2px 4px rgba(15, 218, 137, 0.3)",
      border: "2px solid",
      borderColor: "#961BA9",
    },
    white: {
      bg: "white",
      color: "#961BA9",
      boxShadow: "none",
    },
    gold: {
      bg: "#FFE071",
      color: "#961BA9",
      boxShadow: "none",
    },
  },
  // The default size and variant values
  defaultProps: {
    size: "lg",
    variant: "solid",
  },
};

export const theme = extendTheme({
  fonts: {
    heading: "Montserrat",
    body: "Montserrat",
  },
  colors: {
    brand: {
      primary: "#961BA9",
      secondary: "#FFE071",
      pink: "#FEDCFF",
      yellow: "#FFE071",
      lightYellow: "#FFF9ED",
      lightBlue: "#EDFCFF",
      lightGreen: "#EDFFF1",
      lightPrimary: "#FFEFFF",
      lightPurple: "#961ba926",
      lightGrey: "#EDEDFF",
      lightPrimaryButton: "#FFF6FF",
      textLightGey: "#96A7AF",
      borderGrey: "#F2E4F5",
      white: "#fff",
      dark: "#555555",
      darkGrey: "#979797",
      danger: "#C32727",
      success: "#4C9721",
      error: "#C32727",
      info: "#961BA9",
      flexBg: "#E5E5E5",
      blue: "#3B88C3",
      primaryGradient:
        "linear-gradient(155.54deg, #88039D -6.1%, #FFAC33 76.83%)",
      greyBorderBottom: " #97979745",
      textGrey: "#7B7B7B",
      vlightGreyBG: "#F6F6F6",
      purpleBg: "#FDEDFE",
      purpleText: "#F6BAFF",
      sliderGrey: "#c4c4c4",
      blackText: "#F2E4F5",
      blackTextII: "#5E5873",
      blackTextIII: "#6e6b7b",
      textPrimary: "#5E5878",
      opaqueYellow: "rgba(255, 224, 113, 0.18)",
      opaqueGreen: "rgba(40, 199, 111, 0.12)",
      opaqueRed: "rgba(234, 84, 85, 0.12)",
      mainGreen: "#28C76F",
      primaryII: "#4F4F4F",
    },
    brandPrimaryTheme: {
      100: "#961BA9",
      200: "#961BA9",
      300: "#961BA9",
      400: "#961BA9",
      500: "#961BA9",
    },
  },
  styles: {
    global: {
      // styles for the `body`
      body: {
        fontSize: "14px",
      },
    },
  },
  components: { Button },
});
