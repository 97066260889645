import React, { lazy } from "react";
import TriggerWithRate from "../../modals/TriggerWithRate";

const LogDetails = lazy(() => import("../../modals/LogDetails"));
const InviteUser = lazy(() => import("../../modals/InviteUser"));
const ManageAmbassador = lazy(() => import("../../modals/ManageAmbassador"))
const CreateCompany = lazy(() => import("../../modals/CreateCompany"))
const CreateCompanyEmployee = lazy(() => import("../../modals/CreateCompanyEmployee"))
const PayCustomer = lazy(() => import("../../modals/PayCustomer"))
const UpdateRates = lazy(() => import("../../modals/UpdateRates"))

export const renderModalChild = ({ modalId, isOpen, data }, setModal) => {
  switch (modalId) {
    case "LOG_DETAILS":
      return <LogDetails setModal={setModal} data={data} />;
    case "INVITE_USER":
      return <InviteUser setModal={setModal} data={data} />;
    case "MANAGE_AMBASSADOR":
      return <ManageAmbassador setModal={setModal} data={data} />
    case "CREATE_COMPANY":
      return <CreateCompany setModal={setModal} data={data} />
      case "CREATE_COMPANY_EMPLOYEE":
        return <CreateCompanyEmployee setModal={setModal} data={data} />
      case "EDIT_COMPANY_EMPLOYEE":
        return <CreateCompanyEmployee setModal={setModal} data={data} editing={true}/>
      case "PAY_CUSTOMER":
        return <PayCustomer setModal={setModal} data={data} editing={true}/>
      case "UPDATE_RATES":
        return <UpdateRates setModal={setModal} data={data}/>
      case "TRIGGER_WITH_RATE":
        return <TriggerWithRate setModal={setModal} data={data}/>
    default:
      return <> Nothing Here </>;
  }
};
