import React, { useEffect, useState } from "react";
import {
  IconButton,
  Avatar,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useColorModeValue,
  HStack,
  Text,
} from "@chakra-ui/react";
import { FaBell } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import { ACTIONS } from "./helper";
import { getRates } from "../../util/operations/misc";

const TopNav = ({ sidebar, user, logout, setModal, routes }) => {
const menuActions = ACTIONS(setModal);
const [rates, setRates] = useState();


useEffect(() => {
  const fetchRates = async () => {
    const rates = await getRates()
    setRates(rates.data.usd);
  }

  fetchRates();
}, [])

  return (
    <Flex
      as="header"
      align="center"
      justify="flex-end"
      w="full"
      px="4"
      bg={useColorModeValue("white", "gray.800")}
      h="14"
      mt="40px"
    >
      <HStack px={'6'}>
        <Text color={'purple.600'}>Funding Rate</Text>
        <Text>₦{rates?.buyRate/100 || "--"}/$</Text>
        <Text color={'orange.600'}>Withdrawal Rate</Text>
        <Text>₦{rates?.sellRate/100 || "--"}/$</Text>
      </HStack>
      <IconButton
        aria-label="Menu"
        display={{ base: "inline-flex", md: "none" }}
        onClick={sidebar.onOpen}
        icon={<FiMenu />}
        size="md"
      />
      <Flex align="center">
        <Icon color="gray.500" as={FaBell} cursor="pointer" />
        <Menu>
          <MenuButton w="full">
            <Avatar
              ml="4"
              h="72px"
              w="72px"
              name={user.name}
              cursor="pointer"
            />
          </MenuButton>
          <MenuList fontSize="xs" fontWeight="semibold">
            {menuActions?.map((action) => (
              action.availableTo.includes(user.role) && <MenuItem key={action.title} onClick={() => action.handleClick(setModal)}>
                {action.title}
              </MenuItem>
            ))}

            <MenuDivider />
            <MenuItem onClick={() => logout()}>Log out </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};

export default TopNav;
