import React, { Suspense, useState } from "react";
import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  //eslint-disable-next-line
  useColorModeValue,
  useDisclosure,
  Spinner,
  Center,
} from "@chakra-ui/react";
import { Redirect } from "react-router-dom";
import NotFoundPage from "../not-found";
import SideNavItem from "../../components/SideNav";
import TopNav from "../../components/TopNav";
import { Switch, Route } from "./../../util/router";
import DASHBOARD_ROUTES from "../../util/dashboard-routes";
import { requireAuth, useAuth } from "../../util/auth";
import { renderModalChild } from "./helper";
import ModalComponent from "../../components/Modal";

const Dashboard = () => {
  const sidebar = useDisclosure();

  const { user, logout } = useAuth();

  const displayedRoutes = DASHBOARD_ROUTES[user?.role.toUpperCase()];

  const [modal, setModal] = useState({
    modalId: "",
    isOpen: false,
    data: {},
    title: "No title Yet",
  });

  return (
    <Box
      as="section"
      // bg={useColorModeValue("gray.50", "gray.700")}
      minH="100vh"
    >
      <SideNavItem
        routes={displayedRoutes}
        display={{ base: "none", md: "unset" }}
      />

      <Drawer
        isOpen={sidebar.isOpen}
        onClose={sidebar.onClose}
        placement="left"
      >
        <DrawerOverlay />
        <DrawerContent>
          <SideNavItem routes={displayedRoutes} w="full" borderRight="none" />
        </DrawerContent>
      </Drawer>

      <Box ml={{ base: 0, md: 60 }} transition=".3s ease">
        <Box w="full">
          <TopNav
            routes={displayedRoutes}
            setModal={setModal}
            logout={logout}
            user={user}
            sidebar={sidebar}
          />
        </Box>
        <Box as="main" p="4">
          <ModalComponent
            title={modal.title}
            isOpen={modal.isOpen}
            setIsOpen={setModal}
          >
            <Suspense
              fallback={
                <Center p="10">
                  <Spinner size="xl" />
                </Center>
              }
            >
              {renderModalChild(modal, setModal)}
            </Suspense>
          </ModalComponent>
          <Suspense
            fallback={
              <Center p="10">
                <Spinner size="xl" />
              </Center>
            }
          >
            <Switch>
              {displayedRoutes?.map((route) => {
                const Component = route.component;
                return (
                  <Route
                    key={route.path}
                    path={`/dashboard${route.path}`}
                    render={(rProps) => (
                      <Component setModal={setModal} {...rProps} user={user} />
                    )}
                  />
                );
              })}
              <Redirect from="/dashboard" to="/dashboard/users" />

              <Route
                path="*"
                render={(rprops) => <NotFoundPage {...rprops} />}
              />
            </Switch>
          </Suspense>
        </Box>
      </Box>
    </Box>
  );
};

export default requireAuth(Dashboard);
