import { apiRequest } from "../apiRequest";

export const fetchUserTransactions = (uid) => {
  return apiRequest(`/account/fetch/${uid}`, "GET", null);
};

export const fetchMoreUserTransactions = (uid, lastFetchedId) => {
  return apiRequest(
    `/transaction/fetch/${uid}/more/${lastFetchedId}`,
    "GET",
    null
  );
};

export const fetchUserTransactionByAccount = (accountId) => {
  return apiRequest(
    `/transaction/admin/fetch/account/${accountId}`,
    "GET",
    null
  );
};

export const fetchMoreUserTransactionByAccount = (accountId, lastFetchedId) => {
  return apiRequest(
    `/transaction/admin/fetch/account/${accountId}/more/${lastFetchedId}`,
    "GET",
    null
  );
};
// Fetch Transactions accross app
export const fetchAllTransactions = () => {
  return apiRequest(`/transaction/admin/fetch`, "GET", null);
};

export const fetchMoreTransactions = (lastFetchedId) => {
  return apiRequest(
    `/transaction/admin/fetch/more/${lastFetchedId}`,
    "GET",
    null
  );
};
