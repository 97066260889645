import { apiRequest } from "../apiRequest";

export const fetchUserLogs = (uid) => {
  return apiRequest(`/log/fetch/${uid}`, "GET", null);
};

export const fetchMoreUserLogs = (uid, lastFetchedId) => {
  return apiRequest(`/log/fetch/more/${uid}/${lastFetchedId}`, "GET", null);
};

// Fetch Logs Accross Platform
export const fetchAllLogs = () => {
  return apiRequest(`/log/fetch`, "GET", null);
};

export const fetchMoreLogs = (lastFetchedId) => {
  return apiRequest(`/log/fetch/more/${lastFetchedId}`, "GET", null);
};
