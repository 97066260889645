import { lazy } from "react";
import {
  // FaTachometerAlt,  
  // FaClipboardList,
  // FaMoneyBillWaveAlt,
} from "react-icons/fa";
import { ReactComponent as UserNavIcon } from '../assets/svgs/user-nav-icon.svg'
import { ReactComponent as AmbassadorImage }  from '../assets/svgs/user-ambassador.svg'

// const OverviewPage = lazy(() => import("../dashboard-pages/overview"));
const UsersPage = lazy(() => import("../dashboard-pages/users"));
const LogsPage = lazy(() => import("../dashboard-pages/logs"));
const SingleUser = lazy(() => import("../dashboard-pages/single-user"));
// const DebitsPage = lazy(() => import("../dashboard-pages/debits"));
const AmbassadorsPage = lazy(() => import('../dashboard-pages/ambassadors'))
const SingleAmbassadorPage = lazy(() => import('../dashboard-pages/single-ambassador'))
const CompaniesPage = lazy(() => import('../dashboard-pages/companies'))
const SingleCompanyPage = lazy(() => import('../dashboard-pages/single-company'))

const commonRoutes = [
  {
    icon: UserNavIcon,
    title: "Users",
    slug: "/:userId",
    path: "/users/:userId",
    component: SingleUser,
  },
   {
    icon: UserNavIcon,
    title: "Users",
    path: "/users",
    component: UsersPage,
   },
  {
    icon: AmbassadorImage,    
    title: "Ambassadors",
    slug: ":/ambassadorId",
    path: "/ambassadors/:id/:code",
    component: SingleAmbassadorPage,
  },
  {
    icon: AmbassadorImage,
    title: "Ambassadors",
    path: "/ambassadors",
    component: AmbassadorsPage,
  },  
  {
    icon: AmbassadorImage,    
    title: "Companies",
    slug: ":/companyId",
    path: "/companies/:companyId/:companyName",
    component: SingleCompanyPage,
  },
  {
    icon: AmbassadorImage,
    title: "Companies",
    path: "/companies",
    component: CompaniesPage,
  },  
];

const superAdminRoutes = [
  {
    icon: AmbassadorImage,
    title: "Logs",
    path: "/logs",
    component: LogsPage,
  }, 
]

const ROUTES = {
  SUPER: [...commonRoutes, ...superAdminRoutes],
  ADMIN: [...commonRoutes],
};

export default ROUTES;
