export const ACTIONS = (setModal) => [
  {
    handleClick: () =>
      setModal({
        modalId: "INVITE_USER", 
        isOpen: true,
        title: "Invite User",
      }),
    title: "Invite User",
    availableTo: ['super']
  },
  {
    handleClick: () =>
      setModal({
        modalId: "UPDATE_RATES", 
        isOpen: true,
        title: "Update Rates",
      }),
    title: "Set Rates",
    availableTo: ['super']
  },
];
