import createDataContext from "./createContext";
import { fetchUserAccounts, deleteAccount, deleteCardAuth } from "../util/operations/accounts";
import { createStandaloneToast } from "@chakra-ui/react";
import { toastDefaults } from "../util/constants";

const toast = createStandaloneToast();

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCHING_USER_ACCOUNTS":
      return {
        ...state,
        userAccounts: {
          [action.payload.userId]: {
            isLoading: true,
          },
        },
      };
    case "FETCH_USER_ACCOUNTS_SUCCESS":
      return {
        ...state,
        userAccounts: {
          [action.payload.userId]: {
            isLoading: false,
            accounts: action.payload.data,
          },
        },
        lastFetchedId: action.payload.userId[action.payload.userId.length - 1].uid,
      };
    case "DELETE_USER_ACCOUNTS_SUCCESS":
      return {
        ...state,
        userAccounts: {
          [action.payload.userId]: {
            isLoading: false,
            accounts: state.userAccounts[action.payload.userId].accounts.filter(
              (data) => {
                return data.accountId !== action.payload.accountId
              }
            ),
          },
        },
      };
    case "DELETE_ACCOUNT_CARD_AUTH_SUCCESS":
      return {
        ...state,
        userAccounts: {
          [action.payload.userId]: {
            isLoading: false,
            accounts: state.userAccounts[action.payload.userId].accounts.map(
              (data) => {
                if (data.card && (data.accountId === action.payload.accountId)) {
                  data.card.authorizationCode = null;
                }
                return data
              }
            ),
          },
        },
      };
    default:
      return state;
  }
};

const getUserAccounts = (dispatch) => async (userId, toast) => {
  dispatch({
    type: "FETCHING_USER_ACCOUNTS",
    payload: { userId },
  });

  const { status, data, message } = await fetchUserAccounts(userId);
  
  !status &&
    (await toast({
      ...toastDefaults,
      status: "error",
      title: "Error",
      description: message,
    }));

  data &&
    (await dispatch({
      type: "FETCH_USER_ACCOUNTS_SUCCESS",
      payload: { userId, data: data },
    }));
};

const deleteAnAccount = (dispatch) => async ({userId, accountId}) => {
  const response = window.confirm('Are you sure you want to delete this account?');
  if (!response) return;

  const { status, data, message } = await deleteAccount(accountId);

  toast({
    ...toastDefaults,
      status: status? "success" : "error",
      title: status ? "Success" : "Error",
      description: message,
  });

  data && await dispatch({
    type: "DELETE_USER_ACCOUNTS_SUCCESS",
    payload: { userId, accountId },
  })
}

const deleteACardAuth = (dispatch) => async ({userId, accountId}) => {
  const response = window.confirm('Are you sure you want to delete card authentication for this account?');
  if (!response) return;

  const { status, data, message } = await deleteCardAuth(accountId); 

  toast({
    ...toastDefaults,
      status: status? "success" : "error",
      title: status ? "Success" : "Error",
      description: message,
  });

  data && await dispatch({
    type: "DELETE_ACCOUNT_CARD_AUTH_SUCCESS",
    payload: { userId, accountId },
  })
}

export const { Context, Provider } = createDataContext(
  reducer,
  {
    getUserAccounts,
    deleteAnAccount,
    deleteACardAuth,
  },
  {
    allAccounts: [],
    userAccounts: {},
  }
);
