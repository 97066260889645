/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Code } from "@chakra-ui/layout";
import React from "react";

function NotFoundPage(props) {
  return (
    <Box p="20" w="full" textAlign="center">
      The page <Code>{props.location.pathname}</Code> could not be found.
    </Box>
  );
}

export default NotFoundPage;
