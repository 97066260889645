import createDataContext from "./createContext";
import {
  fetchAllMandates,
  fetchMoreMandates,
  fetchUsersMandates,
} from "../util/operations/mandates";
import { toastDefaults } from "../util/constants";
import { createStandaloneToast } from "@chakra-ui/react";
import {
  deleteMandate,
  toggleActivateMandate,
} from "../util/operations/mandates";

const toast = createStandaloneToast();

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_MANDATES_SUCCESS":
      return {
        ...state,
        allMandatesLoading: false,
        allMandates: action.payload,
        lastFetchedId: action.payload[action.payload.length - 1]._id,
      };
    case "FETCH_MORE_MANDATES_SUCCESS":
      return {
        ...state,
        allMandatesLoading: false,
        allMandates: [...state.app_logs, ...action.payload],
        lastFetchedId: action.payload[action.payload.length - 1]._id,
      };
    case "MANDATES_LOADING":
      return {
        ...state,
        allMandatesLoading: true,
      };
    case "USER_MANDATES_LOADING":
      return {
        ...state,
        allMandatesLoading: true,
        userMandates: {
          user: {
            [action.payload]: {
              isLoading: true,
            },
          },
        },
      };

    case "FETCH_USER_MANDATES_SUCCESS":
      return {
        ...state,
        allMandatesLoading: false,
        userMandates: {
          user: {
            [action.payload.userId]: { mandates: action.payload.data },
          },
        },
      };

    case "DELETE_USER_MANDATE_SUCCESS":
      return {
        ...state,
        userMandates: {
          user: {
            [action.payload.userId]: {
              mandates: state.userMandates.user[
                action.payload.userId
              ].mandates.filter(
                (mandate) =>
                  mandate.mandate.mandateId !== action.payload.mandateId
              ),
            },
          },
        },
      };
    case "ACTIVATE_MANDATE_TOGGLE_SUCCESS":
      return {
        ...state,
        allMandatesLoading: false,
        userMandates: {
          user: {
            [action.payload.userId]: {
              mandates: state.userMandates.user[
                action.payload.userId
              ].mandates.map((mandate) => {
                if (mandate.mandate.mandateId === action.payload.mandateId) {
                  mandate.mandate.active = action.payload.active;
                }
                return mandate;
              }),
            },
          },
        },
      };
    default:
      return state;
  }
};

const getAllMandates = (dispatch) => async (allMandates, toast) => {
  if (allMandates && allMandates.length > 0) {
    return;
  }
  dispatch({
    type: "MANDATES_LOADING",
  });

  const { status, data, message } = await fetchAllMandates();
  !status &&
    (await toast({
      ...toastDefaults,
      status: "error",
      title: "Error",
      description: message,
    }));
  data && (await dispatch({ type: "FETCH_MANDATES_SUCCESS", payload: data }));
};

const getMoreMandates = (dispatch) => async (lastFetchedId, toast) => {
  const { status, data, message } = await fetchMoreMandates(lastFetchedId);
  dispatch({
    type: "MANDATES_LOADING",
  });

  !status &&
    (await toast({
      ...toastDefaults,
      status: "error",
      title: "Error",
      description: message,
    }));
  data &&
    (await dispatch({ type: "FETCH_MORE_MANDATES_SUCCESS", payload: data }));
};

const getUserMandates =
  (dispatch) => async (userId, toast, userMandates, refresh) => {
    const { status, data, message } = await fetchUsersMandates(userId);
    dispatch({
      type: "USER_MANDATES_LOADING",
      payload: userId,
    });

    !status &&
      (await toast({
        ...toastDefaults,
        status: "error",
        title: "Error",
        description: message,
      }));

    data &&
      (await dispatch({
        type: "FETCH_USER_MANDATES_SUCCESS",
        payload: { userId, data: data },
      }));
  };

const getDeleteMandate = (dispatch) => async (userId, mandateId) => {
  const response = window.confirm(
    "Are you sure you want to delete this mandate?"
  );
  if (!response) return;

  const { status, message } = await deleteMandate(userId, mandateId);

  toast({
    ...toastDefaults,
    status: status ? "success" : "error",
    description: message,
    title: status ? "Success" : "Mandate Deletion Error",
  });

  status &&
    (await dispatch({
      type: "DELETE_USER_MANDATE_SUCCESS",
      payload: { userId, mandateId },
    }));
};

const getActiveMandateToggle = (dispatch) => async (userId, mandateId) => {
  const response = window.confirm("Are you sure you want to do this?");
  if (!response) return;

  const { data, status, message } = await toggleActivateMandate(mandateId);

  toast({
    ...toastDefaults,
    status: status ? "success" : "error",
    description: message,
    title: status ? "Success" : "Error",
  });

  status &&
    (await dispatch({
      type: "ACTIVATE_MANDATE_TOGGLE_SUCCESS",
      payload: { userId, mandateId, active: data?.data?.active },
    }));
};

export const { Context, Provider } = createDataContext(
  reducer,
  {
    getAllMandates,
    getMoreMandates,
    getUserMandates,
    getDeleteMandate,
    getActiveMandateToggle,
  },
  {
    allMandatesLoading: false,
    allMandates: [],
    userMandates: {
      user: {
        sample_uid: [],
      },
    },
  }
);
