import { Box, Flex, Image, useColorModeValue } from "@chakra-ui/react";
import NavItem from "./SideNavItem";
import Logo from "../../assets/logo-white.png";
import { useHistory } from "react-router-dom";

const SideNav = (props) => {
  const { routes } = props;
  const {
    location: { pathname },
  } = useHistory();

  const activeRoute = (routeName) => {
    return pathname.indexOf(routeName) > -1 ? true : false;
  };

  return (
    <>
      <Box
        as="nav"
        pos="fixed"
        top="0"
        left="0"
        zIndex="sticky"
        h="full"
        pb="10"
        overflowX="hidden"
        overflowY="auto"
        bg={"brand.primary"}
        borderColor={useColorModeValue("inherit", "gray.700")}
        borderRightWidth="1px"
        w="60"
        {...props}
      >
        <Flex px="4" py="10" align="center">
          <Image w="70%" m="auto" src={Logo} />
        </Flex>
        <Flex
          direction="column"
          as="nav"
          fontSize="sm"
          color="gray.600"
          aria-label="Main Navigation"
        >
          {/*eslint-disable-next-line*/}
          {routes?.map((route) => {
            if (!route.slug) {
              return (
                <NavItem
                  active={activeRoute(route.path)}
                  key={route.path}
                  icon={route.icon}
                  path={route.path}
                >
                  {route.title}
                </NavItem>
              );
            }
          })}
        </Flex>
      </Box>
    </>
  );
};

export default SideNav;
