import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Heading,
} from "@chakra-ui/react";

const ModalComponent = ({ children, isOpen, setIsOpen, title }) => {
  return (
    <>
      <Modal
        size="lg"
        isOpen={isOpen}
        onClose={() => setIsOpen({ isOpen: false })}
      >
        <ModalOverlay />
        <ModalContent py="10">
          <ModalHeader>
            {" "}
            <Heading marginBottom="5" fontSize={"2xl"}>
              {" "}
              {title}{" "}
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>{children}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalComponent;
