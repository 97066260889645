import React from "react";
import { Provider as UsersProvider } from "./usersContext";
import { Provider as MandatesProvider } from "./mandatesContext";
import { Provider as MandatesHistoryProvider } from "./mandatesHistoryContext";
import { Provider as AccountsProvider } from "./accountsContext";
import { Provider as LogsProvider } from "./logsContext";
import { Provider as AmbassadorProvider } from './ambassadorContext'
import { Provider as CompanyProvider } from './companyContext'
const ContextProvider = ({ children }) => {
  return (
    <UsersProvider>
      <MandatesProvider>
        <MandatesHistoryProvider>
          <AccountsProvider>
            <AmbassadorProvider>
              <CompanyProvider>
                <LogsProvider>{children}</LogsProvider>  
              </CompanyProvider>
            </AmbassadorProvider>            
          </AccountsProvider>
        </MandatesHistoryProvider>
      </MandatesProvider>
    </UsersProvider>
  );
};

export default ContextProvider;
