import { apiRequest } from "../apiRequest";

export const fetchUserWithdrawals = (uid) => {
  return apiRequest(`/withdrawal/fetch/${uid}`, "GET", null);
};

export const fetchMoreUserWithdrawals = (uid, lastFetchedId) => {
  return apiRequest(
    `/withdrawal/fetch/${uid}/more/${lastFetchedId}`,
    "GET",
    null
  );
};

// Fetch withdrawals accross app
export const fetchAllWithdrawals = () => {
  return apiRequest(`/withdrawal/admin/fetch`, "GET", null);
};

export const fetchMoreWithdrawals = (lastFetchedId) => {
  return apiRequest(
    `/withdrawal/admin/fetch/more/${lastFetchedId}`,
    "GET",
    null
  );
};

export const fetchWithdrawalDetails = (withdrawalReference) => {
  return apiRequest(
    `/withdrawal/admin/fetch/details/${withdrawalReference}`,
    "GET",
    null
  );
};
