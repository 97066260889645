import React from "react";
import ReactDOM from "react-dom";
import App from "./pages/_app";
import reportWebVitals from "./reportWebVitals";
import { H } from 'highlight.run';

H.init('odz67mep', {
  environment: 'production',
  enableStrictPrivacy: false,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
