import createDataContext from "./createContext";
import { fetchMandateHistory } from "../util/operations/mandates";
import { toastDefaults } from "../util/constants";

const reducer = (state, action) => {
  switch (action.type) {
    case "USER_MANDATES_HISTORY_LOADING":
      return {
        ...state,
        [action.payload.mandateId]: {
          isLoading: true,
        },
      };
    case "USER_MANDATES_HISTORY_SUCCESS":
      return {
        ...state,
        [action.payload.mandateId]: {
          isLoading: false,
          history: action.payload.data,
        },
      };

    default:
      return state;
  }
};

const getUserMandatesHistory =
  (dispatch) => async (userId, mandateId, toast, mandateHistory) => {
    await dispatch({
      type: "USER_MANDATES_HISTORY_LOADING",
      payload: { mandateId },
    });

    if (mandateHistory[mandateId]?.data) {
      return;
    }
    const { status, data, message } = await fetchMandateHistory(mandateId); 

    !status &&
      (await toast({
        ...toastDefaults,
        status: "error",
        title: "Error",
        description: message,
      }));
    data &&
      (await dispatch({
        type: "USER_MANDATES_HISTORY_SUCCESS",
        payload: { mandateId, data: data.data },
      }));
  };

export const { Context, Provider } = createDataContext(
  reducer,
  {
    getUserMandatesHistory,
  },
  {
    mandateHistory: {}
  }
);
