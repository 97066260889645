import createDataContext from "./createContext";
import {
  createCompany,
  fetchCompanies,
  fetchEmployees,
  createEmployee,
  deleteEmployee,
  editEmployee,
  triggerSavingsForEmployee,
  triggerSavingsForAllEmployees,
} from "../util/operations/company";
import { toastSuccess, toastError } from "../util/helper-functions";

const reducer = (state, action) => {
  switch (action.type) {
    case "OPERATION_IN_PROGRESS":    
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_COMPANIES_SUCCESS":
      return {
        ...state,
        companies: action.payload,
        isLoading: false,
      };
    case "CREATE_COMPANY_SUCCESS":
      return {
        ...state,
        companies: [...state.companies, action.payload],
        isLoading: false,
      };
      case "FETCH_COMPANY_SUCCESS":
        return {
          ...state,
          isLoading: false,
          company: action.payload
        };
      case "DELETE_EMPLOYEE_SUCCESS":
        return {
          ...state,
          isLoading: false,
          employees: state.employees.filter(employee => employee.uid !== action.payload.uid)
        };
      case "EDIT_EMPLOYEE_SUCCESS":
        return {
          ...state,
          isLoading: false,
          employees: state.employees.map(employee => {
            if (employee.uid === action.payload.uid) {
              return { ...action.payload, user: employee.user }
            }
            return employee
          })
        };
    case "CREATE_EMPLOYEE_SUCCESS":
      return {
        ...state,
        employees: [...state.employees, action.payload],
        isLoading: false,
      };
    case "FETCH_EMPLOYEES_SUCCESS":
      console.log('ahaann');
      return {
        ...state,
        employees: action.payload,
        isLoading: false,
      };
    case "FETCH_EMPLOYEE_SUCCESS":
      return {
        ...state,
        isLoading: false,
        employee: action.payload
      };
    case "OPERATION_SUCCESS":
      return {
        ...state,
        isLoading: false,
      };
    case "OPERATION_ERROR":
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

const getAllCompanies = (dispatch) => async () => {
  dispatch({
    type: "OPERATION_IN_PROGRESS",
  });
  const { status, data, message } = await fetchCompanies();   

  if (!status) {
    await dispatch({
      type: "OPERATION_ERROR",
    });
    return toastError(message);
  }

  data && (await dispatch({ type: "FETCH_COMPANIES_SUCCESS", payload: data }));
  toastSuccess(message);
};

const dispatchCreateACompany = (dispatch) => async ({ formData }) => {
  dispatch({
    type: "OPERATION_IN_PROGRESS",
  });
  const { status, data, message } = await createCompany({name: formData.name});  

  if (!status) {
    await dispatch({
      type: "OPERATION_ERROR",
    });

    return toastError(message);
  }
  
  data && (await dispatch({ type: "CREATE_COMPANY_SUCCESS", payload: data }));
  toastSuccess(message);

  return status;
};

const getAllCompanyEmployees = (dispatch) => async ({companyId}) => {
  dispatch({
    type: "OPERATION_IN_PROGRESS",
  });

  const { status, data, message } = await fetchEmployees({ companyId });  

  console.log('employees context', data)

  if (!status) {
    await dispatch({
      type: "OPERATION_ERROR",
    });
    return toastError(message);
  }

  data && (await dispatch({ type: "FETCH_EMPLOYEES_SUCCESS", payload: data }));
  toastSuccess(message);
};

const dispatchCreateACompanyEmployee = (dispatch) => async (formData) => {
  dispatch({
    type: "OPERATION_IN_PROGRESS",
  });
  const { status, message } = await createEmployee({...formData});  

  if (!status) {
    await dispatch({
      type: "OPERATION_ERROR",
    });

    return toastError(message);
  } else{
    toastSuccess(message);
    return status;
  }
};

const dispatchDeleteACompanyEmployee = (dispatch) => async (formData) => {
  dispatch({
    type: "OPERATION_IN_PROGRESS",
  });
  const { status, data, message } = await deleteEmployee({...formData});

  if (!status) {
    await dispatch({
      type: "OPERATION_ERROR",
    });

    return toastError(message);
  } else {
    data && (await dispatch({ type: "DELETE_EMPLOYEE_SUCCESS", payload: data }));
    toastSuccess(message);
    return status;
  }
};

const dispatchEditCompanyEmployee = (dispatch) => async (formData) => {
  dispatch({
    type: "OPERATION_IN_PROGRESS",
  });
  const { status, data, message } = await editEmployee({...formData}); 

  if (!status) {
    await dispatch({
      type: "OPERATION_ERROR",
    });

    return toastError(message);
  } else {
    data && (await dispatch({ type: "EDIT_EMPLOYEE_SUCCESS", payload: data }));
    toastSuccess(message);
    return status;
  }

};

const dispatchTriggerSavingsForEmployee = (dispatch) => async (formData) => {
  dispatch({
    type: "OPERATION_IN_PROGRESS",
  });

  const { status, message } = await triggerSavingsForEmployee({ companyId: formData.companyId, mandateId: formData.mandateId, uid: formData.uid, conversionRate: formData.conversionRate});

  if (!status) {
    await dispatch({
      type: "OPERATION_ERROR",
    });

    return toastError(message);
  } else {
    toastSuccess(message);
    dispatch({
      type: "OPERATION_SUCCESS",
    });
    return status;
  }

};

const dispatchTriggerSavingsForAll = (dispatch) => async (formData) => {
  dispatch({
    type: "OPERATION_IN_PROGRESS",
  });

  const confirm = window.confirm('Are you sure you want to save for all employees?');
  if (!confirm) {
    return;
  }

  const { status, message } = await triggerSavingsForAllEmployees({ companyId: formData.companyId});

  if (!status) {
    await dispatch({
      type: "OPERATION_ERROR",
    });

    return toastError(message);
  }

  toastSuccess(message);
  dispatch({
    type: "OPERATION_SUCCESS",
  });

  return status;
};


export const { Context, Provider } = createDataContext(
  reducer,
  {
    getAllCompanies,
    getAllCompanyEmployees,
    dispatchCreateACompany,
    dispatchCreateACompanyEmployee,
    dispatchTriggerSavingsForEmployee,
    dispatchTriggerSavingsForAll,
    dispatchDeleteACompanyEmployee,
    dispatchEditCompanyEmployee
  },
  {
    companies: [],
    employees: [],
    isLoading: false,
  }
);
